import "./App.css";

import { ChakraProvider, Spinner } from "@chakra-ui/react";
import {
  Dashboard,
  EditConstants,
  EditPortfolio,
  Logout,
  NavBar,
  Trade,
  Portfolio,
  Predictions,
} from "./components";
import React, { useEffect, useState } from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { atom, useAtom } from "jotai";

import { Flex } from "@chakra-ui/react";
import { Login } from "./components";
import { loadUser } from "./brain/actions.js";
import { userA } from "./brain/atoms.js";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Trade />,
  },
  {
    path: "/trade",
    element: <Trade />,
  },
  {
    path: "/predictions",
    element: <Predictions />,
  },
  {
    path: "/portfolio",
    element: <Portfolio />,
  },
  {
    path: "/dashboard",
    element: <Dashboard />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/logout",
    element: <Logout />,
  },
  {
    path: "/constants/edit",
    element: <EditConstants />,
  },
  {
    path: "/portfolio/edit",
    element: <EditPortfolio />,
  },
]);

function Main() {
  const [user, setUser] = useAtom(userA);
  const [loading, setLoading] = useState(true);
  async function onLoad() {
    const user = await loadUser();
    setUser(user);
    setLoading(false);
  }
  useEffect(() => {
    onLoad();
  }, []);

  if (loading)
    return (
      <Flex w="100vw" h="100vh" align={"center"} justify="center">
        <Spinner boxSize={24} />
      </Flex>
    );
  return <RouterProvider router={router} />;
}

function App() {
  return (
    <ChakraProvider>
      <Main />
    </ChakraProvider>
  );
}

export default App;
