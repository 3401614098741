import {
  Avatar,
  Box,
  Button,
  Flex,
  HStack,
  IconButton,
  Image,
  Link,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Stack,
  useColorModeValue,
  useDisclosure,
  useBreakpointValue
} from "@chakra-ui/react";
import { CloseIcon, HamburgerIcon } from "@chakra-ui/icons";
import { atom, useAtom } from "jotai";

import { Link as RLink } from "react-router-dom";
import { ReactNode } from "react";
import { userA } from "../brain/atoms.js";

const Links = [
  // { text: "Dashboard", href: "/dashboard" },
  // { text: "Project", href: "/" },
  // { text: "Team", href: "/" },
  { text: "Trade", href: "/trade" },
  { text: "Portfolio", href: "/portfolio" },
  { text: "Predictions", href: "/predictions" },
  

];

const NavLink = ({ link }) => (
  <Link
    as={RLink}
    px={2}
    py={1}
    rounded={"md"}
    _hover={{
      textDecoration: "none",
      bg: useColorModeValue("gray.200", "gray.700"),
    }}
    to={link.href}
  >
    {link.text}
  </Link>
);

export function NavBar() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [user, setUser] = useAtom(userA);
  const isMobile = useBreakpointValue({ base: true, md: false });
  return (
    <>
      <Box
        bg={useColorModeValue("gray.100", "gray.900")}
        px={4}
        w="100%"
        mb={[4, 12]}
      >
        <Flex h={16} alignItems={"center"} justifyContent={"space-between"}>
          <IconButton
            size={"md"}
            icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
            aria-label={"Open Menu"}
            display={{ md: "none" }}
            onClick={isOpen ? onClose : onOpen}
          />
          <HStack spacing={8} alignItems={"center"}>
            {/* {!isMobile && ( */}
            {(
              <Box>
                <Image src="/logo_2.png" boxSize={70} alt="Logo" />
              </Box>
            )}
            <HStack
              as={"nav"}
              spacing={4}
              display={{ base: "none", md: "flex" }}
            >
              {Links.map((link) => (
                <NavLink key={link} link={link} />
              ))}
            </HStack>
          </HStack>
          <Flex alignItems={"center"}>
            {user ? (
              <Menu>
                <MenuButton
                  as={Button}
                  rounded={"full"}
                  variant={"link"}
                  cursor={"pointer"}
                  minW={0}
                >
                  <Avatar
                    size={"sm"}
                    src={
                      user?.avatar ??
                      "https://images.unsplash.com/photo-1493666438817-866a91353ca9?ixlib=rb-0.3.5&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&s=b616b2c5b373a80ffc9636ba24f7a4a9"
                    }
                  />
                </MenuButton>
                <MenuList>
                  {/* <MenuItem>Link 1</MenuItem>
                <MenuItem>Link 2</MenuItem> */}
                  <MenuDivider />
                  <MenuItem as="a" href={"/logout"}>
                    Logout
                  </MenuItem>
                </MenuList>
              </Menu>
            ) : (
              <Link href="/login">
                <Button colorScheme={"blue"}>Login</Button>
              </Link>
            )}
          </Flex>
        </Flex>
        {/* <Flex h="10px" bg={user?.color ?? "red"}></Flex> */}
        <Flex h="3px" bg={user?.color ?? "blue.200"}></Flex>

        {isOpen ? (
          <Box pb={4} display={{ md: "none" }}>
            <Stack as={"nav"} spacing={4}>
              {Links.map((link) => (
                <NavLink key={link.text + "mob"} link={link}></NavLink>
              ))}
            </Stack>
          </Box>
        ) : null}
      </Box>
    </>
  );
}
