import {
  Box,
  Button,
  Center,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Image,
  Input,
  Link,
  Show,
  SimpleGrid,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  CategoryScale,
  Chart as ChartJS,
  LineController,
  LineElement,
  LinearScale,
  PointElement,
  Title,
} from "chart.js";
import React, { useState } from "react";
import { atom, useAtom } from "jotai";
import { buy_stocks, get_predictions, sell_stocks, set_profit_sell_threshold, set_loss_sell_threshold, change_buying_power_no_RL } from "../brain/api.js";
// import { loadUser, patchUser, runScript, sell , amount, ticker} from "../brain/actions";
import { loadUser, patchUser, runScript, sell } from "../brain/actions";
import { predictionsA, userA } from "../brain/atoms.js";

import { Line } from "react-chartjs-2";
import { NavBar } from "./";
import { loginUser } from "../brain/actions";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

let modelPredictions = [];

ChartJS.register(
  LineController,
  LineElement,
  PointElement,
  LinearScale,
  Title,
  CategoryScale
);

export function Trade() {
  // for buy stock button
  const [user, setUser] = useAtom(userA);
  const [predictions, setPredictions] = useAtom(predictionsA);
  const [lastUpdated, setLastUpdated] = useState(null);
  
  const navigate = useNavigate();
  useEffect(() => {
    if (!user) {
      navigate("/login");
    }
  }, [user]);
  const [allocationIGM, setAllocationIGM] = useState(-1.0);
  const [allocationSPY, setAllocationSPY] = useState(-1.0);
  const [allocationSMH, setAllocationSMH] = useState(-1.0);
  const [allocationVUG, setAllocationVUG] = useState(-1.0);

  const [buyTickerValue, setBuyTickerValue] = useState("");
  const [buyticker, setBuyTicker] = useState("");

  // for sell stock button
  const [sellTicker, setSellTicker] = useState("");

  // handle the predictions button
  const [isDisabled, setIsDisabled] = useState(false);

  // handle set_threshold button
  const [profitSellThreshold, setProfitSellThreshold] = useState("");
  const [lossSellThreshold, setLossSellThreshold] = useState("");
  const [bp, setBP] = useState("");

  const handleBuySubmit = async (e) => {
    e.preventDefault();
    try {
      console.log(buyTickerValue, buyticker);
      await buy_stocks(buyTickerValue, buyticker); // Call the amount function with the number value as a parameter
      // Optionally, handle the response or perform other actions
      setBuyTickerValue(""); // Clear the input field
      setBuyTicker("");
    } catch (error) {
      console.error(error);
      // Handle the error
    }
  };

  const handleSellSubmit = async (e) => {
    e.preventDefault();
    try {
      await sell_stocks(sellTicker); // Call the ticker function with the string value as a parameter
      // Optionally, handle the response or perform other actions
      setSellTicker(""); // Clear the input field
    } catch (error) {
      console.error(error);
      // Handle the error
    }
  };

  const handleRunScript = async (e) => {
    e.preventDefault();
    setIsDisabled(true);
    const predictions = await get_predictions();
    setLastUpdated(new Date(predictions.time * 1000));
    setAllocationIGM(predictions.asset_allocation_IGM);
    setAllocationSPY(predictions.asset_allocation_SPY);
    setAllocationSMH(predictions.asset_allocation_SMH);
    setAllocationVUG(predictions.asset_allocation_VUG);
    delete predictions.time;
    delete predictions.asset_allocation_IGM;
    delete predictions.asset_allocation_SPY;
    delete predictions.asset_allocation_SMH;
    delete predictions.asset_allocation_VUG;
    setPredictions(predictions);
    setTimeout(() => {
      setIsDisabled(false); // Enable the button after 3 minutes
    }, 500);
  };

  const handleProfitChangeThreshold = async (e) => {
    e.preventDefault();
    try {
      await set_profit_sell_threshold(profitSellThreshold); // Call the ticker function with the string value as a parameter
      // Optionally, handle the response or perform other actions
      setProfitSellThreshold(""); // Clear the input field
    } catch (error) {
      console.error(error);
      // Handle the error
    }
  };

  const handleLossChangeThreshold = async (e) => {
    e.preventDefault();
    try {
      await set_loss_sell_threshold(lossSellThreshold); // Call the ticker function with the string value as a parameter
      // Optionally, handle the response or perform other actions
      setLossSellThreshold(""); // Clear the input field
    } catch (error) {
      console.error(error);
      // Handle the error
    }
  };

  const handleBpChange = async (e) => {
    e.preventDefault();
    try {
      await change_buying_power_no_RL(bp); // Call the ticker function with the string value as a parameter
      // Optionally, handle the response or perform other actions
      setBP(""); // Clear the input field
    } catch (error) {
      console.error(error);
      // Handle the error
    }
  };

  // BUY
  const handleBuyInputChange = (e) => {
    setBuyTickerValue(e.target.value);
  };

  
  const handleTickerInputChange = (e) => {
    const inputText = e.target.value.toUpperCase();
    setBuyTicker(inputText);
  };
  //SELL
  const handleSellInputChange = (e) => {
    const inputText = e.target.value.toUpperCase();
    setSellTicker(inputText);
  };

  // THRESHOLD
  const handleProfitThresholdInputChange = (e) => {
    setProfitSellThreshold(e.target.value);
  };

  const handleLossThresholdInputChange = (e) => {
    setLossSellThreshold(e.target.value);
  };

  const handleBPInputChange = (e) => {
    setBP(e.target.value);
  };

  const bgColor = useColorModeValue("gray.50", "gray.800");
  const buttonColor1 = useColorModeValue("blue.400", "blue.400");
  const buttonColor2 = useColorModeValue("green.400", "green.400");
  const buttonColor3 = useColorModeValue("red.400", "red.400");

  if (!user) return null;

  return (
    <Flex
      minH={"100vh"}
      align={"center"}
      bg={bgColor}
      direction="column"
      // bg="white"
    >
      <NavBar />
      <Flex mx={10} direction={["column", "row"]}>
        <Stack>
          {/* <Stack spacing={2}>
            <Image
              src="/logo_1.png"
              style={{
                display: "block",
                margin: "0 auto",
                width: "150px",
                height: "auto",
                borderRadius: "50px"
              }}
            />
          </Stack> */}
          <Image
            // src="/trading_bot.png"
            src="/alphatrader.png"
            style={{
              display: "block",
              margin: "0 auto",
              width: "260px", //150
              height: "auto",
              borderRadius: "50px"
            }}
            mb={0}
          />
          {/* <Heading pl={8}>Trade Stocks</Heading> */}

          <Box p={8} minW="400px">
            <Stack spacing={4}>
              <Button
                onClick={handleRunScript}
                isDisabled={isDisabled}
                bg={buttonColor1}
                color={"white"}
                _hover={{
                  bg: "blue.500",
                }}
                style={{ fontSize: "14px" }}
                isLoading={isDisabled}
              >
                SHOW PREDICTIONS
              </Button>
              
              <hr style={{ margin: "10px 0", borderWidth: "1.5px"}} />

              <FormControl id="string-input">
                <FormLabel>Enter profit-sell threshold</FormLabel>
                <Input
                  type="text"
                  value={profitSellThreshold}
                  onChange={handleProfitThresholdInputChange}
                  placeholder="Set Profit Sell Threshold"
                />
              </FormControl>
              <Button
                onClick={handleProfitChangeThreshold}
                bg={buttonColor1}
                color={"white"}
                _hover={{
                  bg: "cyan.500",
                }}
                style={{ fontSize: "14px" }}
              >
                SET PROFIT SELL THRESHOLD
              </Button>

              <hr style={{ margin: "10px 0", borderWidth: "1.5px"}} />

              <FormControl id="string-input">
                <FormLabel>Enter loss-sell threshold</FormLabel>
                <Input
                  type="text"
                  value={lossSellThreshold}
                  onChange={handleLossThresholdInputChange}
                  placeholder="Set Loss Sell Threshold"
                />
              </FormControl>
              <Button
                onClick={handleLossChangeThreshold}
                bg={buttonColor1}
                color={"white"}
                _hover={{
                  bg: "cyan.500",
                }}
                style={{ fontSize: "14px" }}
              >
                SET LOSS SELL THRESHOLD
              </Button>

              <hr style={{ margin: "10px 0", borderWidth: "1.5px"}} />
              
              <FormControl id="string-input">
                <FormLabel>Enter Buying Power</FormLabel>
                <Input
                  type="number"
                  value={bp}
                  onChange={handleBPInputChange}
                  placeholder="Set Buying Power"
                />
              </FormControl>
              <Button
                onClick={handleBpChange}
                bg={buttonColor1}
                color={"white"}
                _hover={{
                  bg: "cyan.500",
                }}
                style={{ fontSize: "14px" }}
              >
                SET BUYING POWER
              </Button>
              
              <hr style={{ margin: "10px 0", borderWidth: "1.5px"}} />

              <FormControl id="ticker-input">
                <FormLabel>Enter the ticker to buy</FormLabel>
                <Input
                  type="string"
                  value={buyticker}
                  onChange={handleTickerInputChange}
                  placeholder="Enter a ticker"
                  style={{ textTransform: 'uppercase' }}
                />
              </FormControl>

              <FormControl id="number-input">
                <FormLabel>Enter the amount to buy</FormLabel>
                <Input
                  type="number"
                  value={buyTickerValue}
                  onChange={handleBuyInputChange}
                  placeholder="Enter a whole number"
                />
              </FormControl>

              <Button
                onClick={handleBuySubmit}
                bg={buttonColor2}
                color={"white"}
                _hover={{
                  bg: "green.500",
                }}
                style={{ fontSize: "14px" }}
              >
                BUY
              </Button>

              <hr style={{ margin: "10px 0", borderWidth: "1.5px"}} />

            </Stack>
            <Stack spacing={4} mt={4}>
              <FormControl id="string-input">
                <FormLabel>Enter the ticker to sell</FormLabel>
                <Input
                  type="text"
                  value={sellTicker}
                  onChange={handleSellInputChange}
                  placeholder="Enter a ticker"
                  style={{ textTransform: 'uppercase' }}
                />
              </FormControl>
              <Button
                onClick={handleSellSubmit}
                bg={buttonColor3}
                color={"white"}
                _hover={{
                  bg: "red.500",
                }}
                style={{ fontSize: "14px" }}
              >
                SELL
              </Button>

              <hr style={{ margin: "10px 0", borderWidth: "1.5px"}} />
              
              {lastUpdated && (
                <h1 id="time" style={{ textAlign: "center" }}>
                  Last updated: {lastUpdated.toLocaleTimeString()}
                </h1>
              )}
              
              {allocationIGM!=-1.0 && (
                    <h1 id="Allocation Percentage IGM" style={{ textAlign: "center" }}>
                      Allocation IGM: {allocationIGM.toFixed(2)}
                    </h1>
                  )}
              {allocationSPY!=-1.0 && (
                <h1 id="Allocation Percentage SPY" style={{ textAlign: "center" }}>
                  Allocation SPY: {allocationSPY.toFixed(2)}
                </h1>
              )}
              {allocationSMH!=-1.0 && (
                <h1 id="Allocation Percentage SMH" style={{ textAlign: "center" }}>
                  Allocation SMH: {allocationSMH.toFixed(2)}
                </h1>
              )}
              {allocationVUG!=-1.0 && (
                <h1 id="Allocation Percentage VUG" style={{ textAlign: "center" }}>
                  Allocation VUG: {allocationVUG.toFixed(2)}
                </h1>
              )}
            </Stack>
          </Box>
        </Stack>
        <Show breakpoint="(min-width: 500px)">
          <Divider
            orientation="vertical"
            colorScheme={"blue"}
            height="80vh"
            borderWidth={1.5}
          />
        </Show>
        <PredictionsGraph isDisabled={isDisabled} />
      </Flex>
    </Flex>
  );
}

function PredictionsGraph({ isDisabled }) {
  const [predictions, setPredictions] = useAtom(predictionsA);
  // console.log("preictions are", predictions);
  if (!predictions) {
    return (
      <Center w={["100vw", "70vw"]} pl={[4, 10]}>
        <Text>No Graphs to display</Text>
      </Center>
    );
  }
  return (
    <SimpleGrid
      justify="center"
      mt={[0, 8]}
      pl={[4, 10]}
      w={["100vw", "70vw"]}
      columns={[1, 2, 2, 2, 2, 3]}
      maxH="80vh"
      overflow={"scroll"}
      position="relative"
    >
      {isDisabled && (
        <Flex
          position={"absolute"}
          bgColor="whiteAlpha.500"
          // bgColor="red"
          w="100%"
          h="100%"
        ></Flex>
      )}
      {Object.keys(predictions).map((key, index) => {
        const data = {
          labels: Array.from({ length: 14 }, (_, index) => String(index + 1)),
          datasets: [
            {
              label: key,
              data: predictions[key],
              backgroundColor: "rgba(0, 123, 255, 0.5)",
              borderColor: "rgba(0, 123, 255, 1)",
              borderWidth: 1,
            },
          ],
        };
        const options = {
          responsive: true,
          width: 400, // Set the desired width of the chart
          height: 300, // Set the desired height of the chart
          maintainAspectRatio: true,
          plugins: {
            title: {
              display: true,
              text: key, // Set the title as the corresponding key
            },
          },
        };
        return (
          <Flex key={key} p={2} mb={2}>
            <Line options={options} data={data}></Line>
          </Flex>
        );
      })}
    </SimpleGrid>
  );
}

export default PredictionsGraph;