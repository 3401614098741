import {
  Box,
  Button,
  Container,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  List,
  ListItem,
  NumberInput,
  NumberInputField,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";
import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { atom, useAtom } from "jotai";

import { patchUser } from "../brain/actions";
import { useNavigate } from "react-router-dom";
import { userA } from "../brain/atoms.js";

const resetDate = (fieldName, setFieldValue) => {
  setFieldValue(fieldName, "1970-01-01");
};

export function EditPortfolio() {
  const [user, setUser] = useAtom(userA);
  const navigate = useNavigate();
  async function onSubmit(values) {
    // patch the user
    const user = await patchUser({ user: { portfolio: values } });
    setUser(user);
    navigate("/dashboard");
  }
  if (!user) return null;
  const initialValues = user.portfolio;
  return (
    <Container maxW="6xl" my={20} minH="100vh">
      <Heading size="lg" mb={4}>
        Portfolio
      </Heading>
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        {(formikProps) => (
          <Form>
            <Field name="amount">
              {({ field, form }) => (
                <FormControl
                  isInvalid={form.errors.amount && form.touched.amount}
                >
                  <FormLabel htmlFor="amount">Amount</FormLabel>
                  <NumberInput
                    {...field}
                    id="amount"
                    placeholder="Amount"
                    name="amount"
                    onChange={(val, numVal) =>
                      form.setFieldValue(field.name, numVal)
                    }
                  >
                    <NumberInputField />
                  </NumberInput>
                  <FormErrorMessage>{form.errors.amount}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name="sell_date">
              {({ field, form }) => (
                <FormControl
                  isInvalid={form.errors.sell_date && form.touched.sell_date}
                >
                  <FormLabel htmlFor="sell_date">Sell Date</FormLabel>
                  <Input {...field} id="sell_date" placeholder="Sell Date" />
                  <Button
                    size="sm"
                    m={2}
                    onClick={() =>
                      resetDate("sell_date", formikProps.setFieldValue)
                    }
                  >
                    Reset
                  </Button>
                  <FormErrorMessage>{form.errors.sell_date}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name="buy_date">
              {({ field, form }) => (
                <FormControl
                  isInvalid={form.errors.buy_date && form.touched.buy_date}
                >
                  <FormLabel htmlFor="buy_date">Buy Date</FormLabel>
                  <Input {...field} id="buy_date" placeholder="Buy Date" />
                  <Button
                    size="sm"
                    m={2}
                    onClick={() =>
                      resetDate("buy_date", formikProps.setFieldValue)
                    }
                  >
                    Reset
                  </Button>
                  <FormErrorMessage>{form.errors.buy_date}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name="shares">
              {({ field, form }) => (
                <FormControl
                  isInvalid={form.errors.shares && form.touched.shares}
                >
                  <FormLabel htmlFor="shares">Shares</FormLabel>
                  <SimpleGrid spacing={3} columns={3}>
                    {Object.keys(initialValues.shares).map((share, index) => (
                      <Box display="flex" alignItems="center">
                        <Box mr={2}>
                          <Text fontWeight={500} minW={20}>
                            {share}
                          </Text>
                        </Box>
                        <Box>
                          <Input
                            type="number"
                            name={`shares.${share}`}
                            value={field.value[share]}
                            onChange={field.onChange}
                            onBlur={field.onBlur}
                            placeholder="0"
                          />
                        </Box>
                      </Box>
                    ))}
                  </SimpleGrid>
                  <FormErrorMessage>{form.errors.shares}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Button
              mt={4}
              // isLoading={formikProps.isSubmitting}
              colorScheme="purple"
              type="submit"
              w="300px"
            >
              Submit
            </Button>
          </Form>
        )}
      </Formik>
    </Container>
  );
}
