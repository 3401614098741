import {
    Wrap, WrapItem ,
    useBreakpointValue,
    Box,
    Button,
    Center,
    Divider,
    Flex,
    FormControl,
    FormLabel,
    Heading,
    Image,
    Input,
    Link,
    Show,
    SimpleGrid,
    Stack,
    Text,
    useColorModeValue,
  } from "@chakra-ui/react";

  import React, { useState } from "react";
  import { atom, useAtom } from "jotai";
  import { get_portfolio } from "../brain/api.js";

  import { loadUser, patchUser, runScript, sell } from "../brain/actions";
  import { predictionsA, userA } from "../brain/atoms.js";
  
  import { NavBar } from "./";
  import { loginUser } from "../brain/actions";
  import { useEffect } from "react";
  import { useNavigate } from "react-router-dom";


  export function Portfolio() {
    const [user, setUser] = useAtom(userA);
    const [isDisabled, setIsDisabled] = useState(false);
    const navigate = useNavigate();
    const bgColor = useColorModeValue("gray.50", "gray.800");
    const buttonColor1 = useColorModeValue("blue.400", "blue.400");
    const [portfolio, setPortfolio] = useState([]);
    useEffect(() => {
        if (!user) {
        navigate("/login");
        }
    }, [user]);
    if (!user) return null;

    const handleGetPortfolio = async (e) => {
        e.preventDefault();
        setIsDisabled(true);
        const portfolio_ = await get_portfolio();
        const portfolio_transformed = transformPortfolioData(portfolio_)
        setPortfolio(portfolio_transformed)
        setIsDisabled(false);


    };
    return (
        <Flex
            minH={"100vh"}
            align={"center"}
            bg={bgColor}
            direction="column"
            // bg="white"
        >
        <NavBar />
        <Flex mx={10} direction={["column", "row"]}>
            <Stack>
            <Image
                // src="/trading_bot.png"
                src="/alphatrader.png"
                style={{
                display: "block",
                margin: "0 auto",
                width: "260px", //150
                height: "auto",
                borderRadius: "50px"
                }}
                mb={0}
            />
            <Box p={8} minW="400px" maxw="700px">
            <Stack spacing={4}>
            <Center>
              <Button
                onClick={handleGetPortfolio}
                isDisabled={isDisabled}
                bg={buttonColor1}
                color={"white"}
                _hover={{
                  bg: "blue.500",
                }}
                style={{ fontSize: "16px"} }
                isLoading={isDisabled}
                m={4}
                w={["50vw", "40vw"]}
                
              >
                UPDATE PORTFOLIO
              </Button>
              </Center>
            </Stack>
            </Box>
            <RenderUserPortfolio portfolioObj={portfolio}></RenderUserPortfolio>
        </Stack>
        </Flex>
        
      </Flex>
    );
  }

function RenderUserPortfolio({ portfolioObj }) {
    console.log(portfolioObj)
    const flexDirection = useBreakpointValue({ base: "column", md: "row" });
    
    if (portfolioObj.length===0) {
      return (
        <Center w={["100vw", "70vw"]} pl={[4, 10] } >
          <Text>No Open Positions, Update the Porfolio</Text>
        </Center>
      );
    } else {
    // Render your portfolio data here
    return (
      <Wrap spacing="4" justify="center" align="center" direction={flexDirection}>
        {Object.entries(portfolioObj).map(([user, tickerData]) => (
          <WrapItem key={user}>
            <Box
              border="1px"
              borderRadius="md"
              borderColor="gray.200"
              p="4"
              maxW="400px"
              w={["70vw", "50vw"]}
            >
              <Text fontSize="xl" fontWeight="bold" color='yellow.500'>
                User: {user}
                <br />
              </Text>
              <Text fontSize="l" fontWeight="semibold" color='gray.500'>
              Portfolio: {tickerData["Portfolio"].toFixed(2)}
              </Text>
              <Text fontSize="l" fontWeight="semibold" color='gray.500'>
              Day Trade Count: {tickerData["DayTradeCount"]}
              </Text>

              {Object.entries(tickerData).map(([ticker, data]) => {
                if (ticker !== "Portfolio" && ticker !== "DayTradeCount") {
                  return (
                    <Box key={ticker} mt="2">
                      <Text fontWeight="bold">{ticker}</Text>
                      {Object.entries(data).map(([field, value]) => (
                        <Text
                          key={field}
                          // color={(field === "P/L" || field === "Gain/Loss") ? (value < 0 ? "red" : "green") : undefined}
                          color={(field === "P/L" || field === "PNL" ) ? (value < 0 ? "red" : "green") : undefined}

                        >
                          {field}: {field === "PNL" ? `${value.toFixed(2)}%` : value.toFixed(2)}
                        </Text>
                        
                      ))}
                    </Box>
                  );
                }
                return null; // Skip rendering if the key is "Portfolio"
              })}
            </Box>
          </WrapItem>
        ))}
      </Wrap>
    );
    
    }
  }


  function transformPortfolioData(data) {
    const transformedPortfolio = {};
  
    // Loop through each portfolio item
    for (const item of data) {
      const { user, Portfolio, DayTradeCount, ...tickerData } = item; // Extract user and ticker data
      
      if (!(user in transformedPortfolio)) {
        transformedPortfolio[user] = {}; // Create user object if it doesn't exist
      }
      transformedPortfolio[user]["Portfolio"] = Portfolio;
      transformedPortfolio[user]["DayTradeCount"] = DayTradeCount;
      // Loop through each ticker data for the user
      for (const [key, value] of Object.entries(tickerData)) {
        const ticker = key.split("_")[0]; // Extract the ticker name
  
        if (!(ticker in transformedPortfolio[user])) {
          transformedPortfolio[user][ticker] = {}; // Create ticker object if it doesn't exist
        }
  
        const field = key.split("_")[1]; // Extract the field name (e.g., "entry_price", "qty", "market_val")
        transformedPortfolio[user][ticker][field] = value; // Assign the value to the corresponding field
        
      }
    }
  
    return transformedPortfolio;
  }