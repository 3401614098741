import {
  AbsoluteCenter,
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Link,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { atom, useAtom } from "jotai";
import { loginUser, logoutUser } from "../brain/actions";
import { useEffect, useState } from "react";

import { NavBar } from "./";
import { useNavigate } from "react-router-dom";
import { userA } from "../brain/atoms.js";

export function Logout() {
  const [user, setUser] = useAtom(userA);
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      logoutUser();
      setUser(null);
    }
  }, [user]);

  return (
    <Flex
      minH={"100vh"}
      // align={"center"}
      // justify={"center"}
      bg={useColorModeValue("gray.50", "gray.800")}
      direction="column"
    >
      <NavBar />
      <AbsoluteCenter>You are successfully logged out</AbsoluteCenter>
    </Flex>
  );
}
