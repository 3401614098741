import {
  Image,
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Link,
  Stack,
  Text,
  Center, 
  useBreakpointValue,
  useColorModeValue,
} from "@chakra-ui/react";


import { atom, useAtom } from "jotai";
import { useEffect, useState } from "react";

import { NavBar } from "./";
import { loginUser } from "../brain/actions";
import { useNavigate } from "react-router-dom";
import { userA } from "../brain/atoms.js";

export function Login() {
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");
  const [user, setUser] = useAtom(userA);
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      navigate("/trade");
    }
  }, [user]);
  async function handleLogin() {
    const user = await loginUser({ username, password });
    setUser(user);
    navigate("/trade");
  }
  const logoWidth = useBreakpointValue({ base: "120px", md: "150px" });
  
  return (
    
    <Flex
      direction={"column"}
      bg={useColorModeValue("gray.50", "gray.800")}
      minH={"100vh"}
    >
      <NavBar />
      <Stack spacing={2}>
        {/* <Flex justifyContent="center">
          <Image
            src="/logo_1.png"
            style={{
              width: logoWidth,
              height: "auto",
              borderRadius: "50px",
              margin: "0 auto",
            }}
            alt="Logo"
          />
        </Flex> */}
        <Flex justifyContent="center">
          <Image
            // src="/trading_bot.png"
            src="/alphatrader.png"
            style={{
              width: "310px", //170
              height: "auto",
              borderRadius: "50px",
              margin: "0 auto",
            }}
            alt="Trading Bot"
          />
        </Flex>
      </Stack>
      <Flex align={"center"} justify={"center"}>
        <Stack spacing={8} mx={"auto"} maxW={"lg"} py={5} px={6} textAlign="center">
          <Stack align={"center"}>
            <Heading fontSize={"3xl"}>Sign in to your account</Heading>
            <Text fontSize={"l"} color={"gray.600"}>
              <Link color={"blue.400"}>Let's go to the moon</Link> ✌️
            </Text>
          </Stack>
          <Box
            rounded={"lg"}
            bg={useColorModeValue("white", "gray.700")}
            boxShadow={"lg"}
            p={8}
            w={{ base: "80vw", md: "25vw" }}
            // w="25vw"
          >
            <Stack spacing={4} whiteSpace="nowrap">
              <FormControl id="username">
                <FormLabel>Username</FormLabel>
                <Input
                  type="username"
                  value={username}
                  onChange={(e) => {
                    setUsername(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl id="password">
                <FormLabel>Password</FormLabel>
                <Input
                  type="password"
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                />
              </FormControl>
              <Button colorScheme={"cyan"} onClick={handleLogin}>
                Submit
              </Button>
            </Stack>
          </Box>
        </Stack>
      </Flex>
    </Flex>
  );
}
