import {
  Box,
  Button,
  Checkbox,
  CloseButton,
  Container,
  Flex,
  FormControl,
  FormLabel,
  Input,
  NumberInput,
  NumberInputField,
  SimpleGrid,
} from "@chakra-ui/react";
import { Field, FieldArray, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { atom, useAtom } from "jotai";

import { patchUser } from "../brain/actions";
import { useNavigate } from "react-router-dom";
import { userA } from "../brain/atoms.js";

export const EditConstants = () => {
  const [user, setUser] = useAtom(userA);
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  async function onSubmit(values) {
    // patch the user
    setLoading(true);
    const user = await patchUser({ user: { constants: values } });
    setUser(user);
    setLoading(false);
    navigate("/dashboard");
  }
  if (!user) return null;
  const initialValues = user.constants;
  return (
    <Container maxW="6xl" my={20}>
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        {({ handleSubmit, handleChange, values }) => (
          <Form onSubmit={handleSubmit} isLoading={isLoading}>
            <Field name="SEED_AMOUNT">
              {({ field, form }) => (
                <FormControl isRequired mt={2}>
                  <FormLabel htmlFor="SEED_AMOUNT">Seed Amount</FormLabel>
                  <NumberInput
                    name="SEED_AMOUNT"
                    onChange={(val, numVal) =>
                      form.setFieldValue(field.name, numVal)
                    }
                    value={values.SEED_AMOUNT}
                  >
                    <NumberInputField />
                  </NumberInput>
                </FormControl>
              )}
            </Field>
            <Field name="MAX_CONCURRENT_BUY">
              {({ field, form }) => (
                <FormControl isRequired mt={2}>
                  <FormLabel htmlFor="MAX_CONCURRENT_BUY">
                    Max Concurrent Buy
                  </FormLabel>
                  <NumberInput
                    name="MAX_CONCURRENT_BUY"
                    onChange={(val, numVal) =>
                      form.setFieldValue(field.name, numVal)
                    }
                    value={values.MAX_CONCURRENT_BUY}
                  >
                    <NumberInputField />
                  </NumberInput>
                </FormControl>
              )}
            </Field>
            <Field name="LOOKBACK_INTERVAL">
              {({ field, form }) => (
                <FormControl isRequired mt={2}>
                  <FormLabel htmlFor="LOOKBACK_INTERVAL">
                    Lookback Interval
                  </FormLabel>
                  <NumberInput
                    name="LOOKBACK_INTERVAL"
                    onChange={(val, numVal) =>
                      form.setFieldValue(field.name, numVal)
                    }
                    value={values.LOOKBACK_INTERVAL}
                  >
                    <NumberInputField />
                  </NumberInput>
                </FormControl>
              )}
            </Field>
            <FormControl my={6} isRequired mt={2}>
              <FormLabel htmlFor="TICKERS">TICKERS:</FormLabel>
              <FieldArray name="TICKERS">
                {(arrayHelpers) => (
                  <SimpleGrid spacing={4} columns={4} alignItems="center">
                    {values.TICKERS.map((ticker, index) => (
                      <Flex key={index} mb={2} alignItems="center">
                        <Input
                          type="text"
                          name={`TICKERS.${index}`}
                          value={ticker}
                          onChange={handleChange}
                          placeholder={`Ticker #${index + 1}`}
                          mr={2}
                        />
                        <CloseButton
                          onClick={() => arrayHelpers.remove(index)}
                          variant="link"
                          size="sm"
                          color={"purple"}
                        ></CloseButton>
                      </Flex>
                    ))}
                    <Button
                      onClick={() => arrayHelpers.push("")}
                      colorScheme="purple"
                      size="sm"
                      mb={2}
                    >
                      Add Ticker
                    </Button>
                  </SimpleGrid>
                )}
              </FieldArray>
            </FormControl>
            <Field name="MIN_PREV_TRUE">
              {({ field, form }) => (
                <FormControl isRequired mt={2}>
                  <FormLabel htmlFor="MIN_PREV_TRUE">Min Prev True</FormLabel>
                  <NumberInput
                    name="MIN_PREV_TRUE"
                    onChange={(val, numVal) =>
                      form.setFieldValue(field.name, numVal)
                    }
                    value={values.MIN_PREV_TRUE}
                  >
                    <NumberInputField />
                  </NumberInput>
                </FormControl>
              )}
            </Field>
            <Field name="SKIP_DAYS">
              {({ field, form }) => (
                <FormControl isRequired mt={2}>
                  <FormLabel htmlFor="SKIP_DAYS">Skip Days</FormLabel>
                  <NumberInput
                    name="SKIP_DAYS"
                    onChange={(val, numVal) =>
                      form.setFieldValue(field.name, numVal)
                    }
                    value={values.SKIP_DAYS}
                  >
                    <NumberInputField />
                  </NumberInput>
                </FormControl>
              )}
            </Field>
            <Field name="BUY_SELL_GAP">
              {({ field, form }) => (
                <FormControl isRequired mt={2}>
                  <FormLabel htmlFor="BUY_SELL_GAP">Buy Sell Gap</FormLabel>
                  <NumberInput
                    name="BUY_SELL_GAP"
                    onChange={(val, numVal) =>
                      form.setFieldValue(field.name, numVal)
                    }
                    value={values.BUY_SELL_GAP}
                  >
                    <NumberInputField />
                  </NumberInput>
                </FormControl>
              )}
            </Field>
            <Field name="STOCK_THRESHOLD">
              {({ field, form }) => (
                <FormControl isRequired mt={2}>
                  <FormLabel htmlFor="STOCK_THRESHOLD">
                    Stock Threshold
                  </FormLabel>
                  <NumberInput
                    name="STOCK_THRESHOLD"
                    onChange={(val, numVal) =>
                      form.setFieldValue(field.name, numVal)
                    }
                    value={values.STOCK_THRESHOLD}
                  >
                    <NumberInputField />
                  </NumberInput>
                </FormControl>
              )}
            </Field>
            <FormControl isRequired mt={2}>
              <FormLabel htmlFor="DIFFERENCE">Difference</FormLabel>
              <Input
                name="DIFFERENCE"
                onChange={handleChange}
                value={values.DIFFERENCE}
              />
            </FormControl>
            <FormControl isRequired mt={2}>
              <FormLabel htmlFor="SIMULATION_PERIOD">
                Simulation Period
              </FormLabel>
              <Input
                name="SIMULATION_PERIOD"
                onChange={handleChange}
                value={values.SIMULATION_PERIOD}
              />
            </FormControl>
            <FormControl isRequired mt={2}>
              <FormLabel htmlFor="STOCK_DATA_START">Stock Data Start</FormLabel>
              <Input
                name="STOCK_DATA_START"
                onChange={handleChange}
                value={values.STOCK_DATA_START}
              />
            </FormControl>
            <FormControl isRequired mt={2}>
              <FormLabel htmlFor="STOCK_DATA_END">Stock Data End</FormLabel>
              <Input
                name="STOCK_DATA_END"
                onChange={handleChange}
                value={values.STOCK_DATA_END}
              />
            </FormControl>
            <FormControl isRequired mt={2}>
              <FormLabel htmlFor="SIMULATION_START">Simulation Start</FormLabel>
              <Input
                name="SIMULATION_START"
                onChange={handleChange}
                value={values.SIMULATION_START}
              />
            </FormControl>
            <FormControl isRequired mt={2}>
              <FormLabel htmlFor="SIMULATION_ITERATIONS">
                Simulation Iterations
              </FormLabel>
              <Input
                name="SIMULATION_ITERATIONS"
                onChange={handleChange}
                value={values.SIMULATION_ITERATIONS}
              />
            </FormControl>
            <FormControl isRequired mt={2}>
              <FormLabel htmlFor="API_KEY">API Key</FormLabel>
              <Input
                name="API_KEY"
                onChange={handleChange}
                value={values.API_KEY}
              />
            </FormControl>
            <FormControl isRequired mt={2}>
              <FormLabel htmlFor="API_SECRET">API Secret</FormLabel>
              <Input
                name="API_SECRET"
                onChange={handleChange}
                value={values.API_SECRET}
              />
            </FormControl>
            <FormControl mt={2}>
              <FormLabel htmlFor="IS_PAPER">Is Paper</FormLabel>
              <Checkbox
                name="IS_PAPER"
                onChange={handleChange}
                isChecked={values.IS_PAPER}
              />
            </FormControl>
            <Button mt={4} colorScheme="purple" type="submit" w="300px">
              Submit
            </Button>
          </Form>
        )}
      </Formik>
    </Container>
  );
};
