import {
  Box,
  Button,
  ButtonGroup,
  Card,
  Container,
  Flex,
  HStack,
  Heading,
  IconButton,
  Link,
  List,
  ListItem,
  SimpleGrid,
  Stack,
  Tag,
  Text,
} from "@chakra-ui/react";
import { HiPause, HiPlay } from "react-icons/hi";
import React, { useEffect, useState } from "react";
import { atom, useAtom } from "jotai";
import { loadUser, patchUser, runScript, sell } from "../brain/actions";

import { NavBar } from "./";
import { useNavigate } from "react-router-dom";
import { userA } from "../brain/atoms.js";

function ConstantVal({ entry }) {
  const value = entry[1];
  if (Array.isArray(value)) {
    return (
      <SimpleGrid columns={5}>
        {value.map((v) => (
          <Tag key={v} colorScheme="purple" m={1}>
            {v}
          </Tag>
        ))}
      </SimpleGrid>
    );
  }
  return <Text>{String(value)}</Text>;
}

function Item({ entry }) {
  if (entry[0] === "shares") {
    console.log(entry);
    return <ShareView value={entry[1]} />;
  }
  return (
    <Flex align={"center"}>
      <Text fontSize={"lg"} mr={2} fontWeight={600}>
        {entry[0]}:
      </Text>
      <ConstantVal entry={entry} />
    </Flex>
  );
}
function ShareView({ value }) {
  if (Object.keys(value).length === 0) {
    return (
      <Flex>
        <Text fontWeight={700} mr={2}>
          Shares:
        </Text>
        <Text>No shares yet</Text>
      </Flex>
    );
  }
  return (
    <HStack>
      <Text fontWeight={700} mr={2}>
        Shares:
      </Text>
      <SimpleGrid columns={4} spacing={2} w="100%">
        {Object.keys(value).map((k) => {
          return (
            <Flex>
              <Text fontWeight={600} mr={1}>
                {k}:
              </Text>
              <Text>{Math.round(value[k] * 100) / 100}</Text>
            </Flex>
          );
        })}
      </SimpleGrid>
    </HStack>
  );
}

export function Dashboard() {
  const [user, setUser] = useAtom(userA);
  const navigate = useNavigate();
  useEffect(() => {
    console.log("user", user);
    if (!user) {
      navigate("/login");
    }
  }, [user]);
  const status = user?.status;
  const handleStatusChange = async () => {
    const newStatus = status === "paused" ? "active" : "paused";
    const user = await patchUser({ user: { status: newStatus } });
    setUser(user);
  };
  if (!user) return null;
  const { constants, portfolio } = user;
  return (
    <Flex direction={"column"}>
      <NavBar />

      <Container maxW="6xl" my={10}>
        <Stack align={"center"}>
          <ButtonGroup mb={4} spacing={6}>
            <Button
              minW={40}
              onClick={() => {
                runScript();
              }}
              colorScheme="purple"
            >
              Run Script Now
            </Button>
            <Button
              minW={40}
              onClick={() => {
                sell();
              }}
              colorScheme="purple"
            >
              Sell All
            </Button>
            <Button
              minW={40}
              leftIcon={status === "active" ? <HiPause /> : <HiPlay />}
              colorScheme="purple"
              onClick={handleStatusChange}
            >
              {status === "active" ? "Pause" : "Resume"}
            </Button>
          </ButtonGroup>
          <HStack w="100%" spacing={3} align={"stretch"}>
            <Card p={4} shadow="lg" flexBasis={"50%"}>
              <Heading size="2xl" mb={4}>
                Constants
              </Heading>
              <List spacing={3}>
                {Object.entries(constants).map((entry) => (
                  <Item key={entry.key} entry={entry}></Item>
                ))}
              </List>
              <Link href={"/constants/edit"}>
                <Button mt={10} colorScheme={"purple"}>
                  Edit constants
                </Button>
              </Link>
            </Card>
            <Card p={4} shadow="lg" flexBasis={"50%"}>
              <Heading size="2xl" mb={4}>
                Portfolio
              </Heading>
              <List spacing={3}>
                {Object.entries(portfolio).map((entry) => (
                  <Item key={entry.key} entry={entry}></Item>
                ))}
              </List>
              <Link href={"/portfolio/edit"}>
                <Button mt={10} colorScheme={"purple"}>
                  Edit portfolio
                </Button>
              </Link>
            </Card>
          </HStack>
        </Stack>
      </Container>
    </Flex>
  );
}
