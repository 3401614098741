import * as api from "./api.js";

export const loginUser = async ({ username, password }) => {
  const user = await api.loginUser({ username, password });
  return user;
};

export const loadUser = async () => {
  try {
    const user = await api.loadUser();
    return user;
  } catch (err) {
    console.log(err);
    return null;
  }
};

export async function patchUser({ user }) {
  const db_user = await api.patchUser({ user });
  return db_user;
}

export async function logoutUser() {
  const db_user = await api.logoutUser();
  return db_user;
}

export async function runScript() {
  const res = await api.runScript();
  return res;
}

export async function sell() {
  const res = await api.sell();
  return res;
}
