import axios from "axios";

const instance = axios.create({
  baseURL: process.env.REACT_APP_SERVER,
  timeout: 5000,
});

export const loginUser = async ({ username, password }) => {
  const resp = await instance.post("/auth/login", { username, password });
  return resp.data;
};

export const loadUser = async () => {
  const resp = await instance.get("/user");
  return resp.data;
};

export const patchUser = async ({ user }) => {
  const resp = await instance.patch("/user", { user });
  return resp.data;
};

export const logoutUser = async () => {
  const resp = await instance.get("/logout");
  return resp.data;
};

export const runScript = async () => {
  const resp = await instance.post("/run", {
    time: 10,
  });
  return resp.data;
};

export const sell = async () => {
  const resp = await instance.post("/sell", {
    tickers: null,
  });
  return resp.data;
};

export const buy_stocks = async (value, ticker) => {
  console.log(value, ticker);
  const resp = await instance.post("/buy_stocks", {
    value: value,
    ticker: ticker
  });
  return resp.data;
};

export const sell_stocks = async (ticker) => {
  const resp = await instance.post("/sell_stocks", {
    ticker: ticker,
  });
  return resp.data;
};

export const set_profit_sell_threshold = async (value) => {
  const resp = await instance.post("/set_threshold_profit", {
    "threshold": value,
  });
  return resp.data;
};

export const set_loss_sell_threshold = async (value) => {
  const resp = await instance.post("/set_threshold_loss", {
    "threshold": value,
  });
  return resp.data;
};

export const change_buying_power_no_RL = async (value) => {
  const resp = await instance.post("/change_buying_power_no_RL", {
    "bp": value,
  });
  return resp.data;
};


export const get_predictions = async () => {
  const resp = await instance.post("/get_predictions", {
    value: null,
  }, { 
    timeout: 10 * 60 * 1000, // 10 mins
  });
  return resp.data;
};

export const get_predictions_without_login = async () => {
  const resp = await instance.post("/get_predictions_without_login", {
    value: null,
  }, { 
    timeout: 10 * 60 * 1000, // 10 mins
  });
  return resp.data;
};

export const get_portfolio = async () => {
  const resp = await instance.post("/get_portfolio", {
    value: null,
  }, { 
    timeout: 2 * 60 * 1000, // 2 mins
  });
  return resp.data;
};
